/**********************************
* IMPORTS
***********************************/
/*
======================================================================
VARIABLES
======================================================================
*/
.grad-bg {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.55+0,0.55+100;Neutral+Density */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.55)), to(rgba(0, 0, 0, 0.55)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.55) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8c000000', endColorstr='#8c000000', GradientType=0);
  /* IE6-9 */
}
.gradient-bottom {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.65)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
  /* IE6-9 */
}
.w-1920 {
  max-width: 1920px;
  width: 100%;
  margin-inline: auto;
}
.grid {
  display: -ms-grid;
  display: grid;
}
.padding-lr {
  padding-inline: 30px;
}
@media screen and (min-width: 768px) {
  .padding-lr {
    padding-inline: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .padding-lr {
    padding-inline: 80px;
  }
}
@media screen and (min-width: 1600px) {
  .padding-lr {
    padding-inline: 100px;
  }
}
.padding {
  padding-inline: 30px;
  padding-block: 32px;
}
@media screen and (min-width: 768px) {
  .padding {
    padding-inline: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .padding {
    padding-inline: 80px;
  }
}
@media screen and (min-width: 1600px) {
  .padding {
    padding-inline: 100px;
  }
}
@media screen and (min-width: 768px) {
  .padding {
    padding-block: 64px;
  }
}
.margin-lr {
  margin-inline: 30;
}
@media screen and (min-width: 768px) {
  .margin-lr {
    margin-inline: 50;
  }
}
@media screen and (min-width: 1024px) {
  .margin-lr {
    margin-inline: 100;
  }
}
@media screen and (min-width: 1600px) {
  .margin-lr {
    margin-inline: 140;
  }
}
/*  Guidlines for font-size range
    Formula: font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
    Example: font-size: calc(70px + (84 - 70) * ((100vw - 300px) / (1600 - 300)));

    Usage: .font-size(48,28);
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  color: #7ac414;
  font-size: calc(48px + ( 80 - 48 ) * ((100vw - 370px) / (1920 - 370)));
  line-height: 1.1;
  font-weight: 700;
}
@media screen and (min-width: 1920px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 80px;
  }
}
h2 {
  font-size: calc(40px + ( 60 - 40 ) * ((100vw - 370px) / (1920 - 370)));
  margin-bottom: 20px;
}
@media screen and (min-width: 1920px) {
  h2 {
    font-size: 60px;
  }
}
h3 {
  font-weight: 700;
  font-size: calc(20px + ( 48 - 20 ) * ((100vw - 370px) / (1920 - 370)));
  margin-bottom: 22px;
}
@media screen and (min-width: 1920px) {
  h3 {
    font-size: 48px;
  }
}
h4 {
  font-size: calc(20px + ( 30 - 20 ) * ((100vw - 370px) / (1920 - 370)));
  margin-bottom: 30px;
}
@media screen and (min-width: 1920px) {
  h4 {
    font-size: 30px;
  }
}
h5,
h6 {
  font-size: calc(16px + ( 20 - 16 ) * ((100vw - 370px) / (1920 - 370)));
  margin-bottom: 5px;
}
@media screen and (min-width: 1920px) {
  h5,
  h6 {
    font-size: 20px;
  }
}
p,
li,
a,
td,
span,
legend,
label,
.wpcf7-response-output {
  font-family: 'Montserrat', sans-serif;
  color: #1C1C1C;
  line-height: 1.5;
  font-weight: 400;
  font-size: calc(16px + ( 20 - 16 ) * ((100vw - 370px) / (1920 - 370)));
}
@media screen and (min-width: 1920px) {
  p,
  li,
  a,
  td,
  span,
  legend,
  label,
  .wpcf7-response-output {
    font-size: 20px;
  }
}
a,
button,
.wp-block-button__link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  text-decoration: none;
}
a:hover,
button:hover,
.wp-block-button__link:hover {
  text-decoration: underline;
}
mark {
  color: #7ac414;
  background-color: #FFFFFF;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
p:not(:last-child) {
  margin-bottom: 18px;
}
p:only-child {
  margin-bottom: 0px;
}
/*--------------------------------------------------------------
## Menus
--------------------------------------------------------------*/
* {
  margin: 0;
  padding: 0;
}
/* SCROLLBAR CUSTOMIZATION */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
  background-color: #061986;
}
/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}
.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
          box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}
/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}
/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
  content: "";
  display: table;
  table-layout: fixed;
}
.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
  clear: both;
}
/**********************************
* GLOBAL
***********************************/
#content {
  margin: 0;
}
.grecaptcha-badge {
  opacity: 0 !important;
}
.error-block {
  padding-bottom: 100px;
}
.error-block h1 {
  font-size: calc(80px + ( 180 - 80 ) * ((100vw - 370px) / (1920 - 370)));
  text-shadow: 1px 1px 1px #1C1C1C, 2px 2px 1px #1C1C1C, 3px 3px 1px #1C1C1C, 4px 4px 1px #1C1C1C, 5px 5px 1px #1C1C1C, 6px 6px 1px #1C1C1C, 7px 7px 1px #1C1C1C, 8px 8px 1px #1C1C1C, 25px 25px 8px rgba(0, 0, 0, 0.2);
}
@media screen and (min-width: 1920px) {
  .error-block h1 {
    font-size: 180px;
  }
}
.error-block h3 {
  color: #7ac414;
}
.error-block .back-home {
  background-color: #7ac414;
  border: 2px solid #7ac414;
  padding: 8px 0px;
  font-weight: 300;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  border-radius: 50px;
  text-align: center;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  margin: auto;
  margin-top: 40px;
  width: 100%;
  display: block;
  color: white;
  padding: 8px 20px;
}
.error-block .back-home:hover {
  background-color: transparent;
  color: #7ac414;
  opacity: 1;
}
.hidden {
  overflow-y: hidden;
}
html {
  overflow-x: hidden;
}
body {
  position: relative;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 -20px 13px 4px rgba(0, 0, 0, 0.11);
          box-shadow: 0 -20px 13px 4px rgba(0, 0, 0, 0.11);
}
html,
body {
  scroll-behavior: smooth;
}
.button_container {
  display: block;
  position: absolute;
  top: 58px;
  right: 9%;
  height: 27px;
  width: 49px;
  cursor: pointer;
  z-index: 500;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.button_container:hover {
  opacity: 0.7;
}
.button_container.active .top {
  -webkit-transform: translateY(11px) translateX(0) rotate(45deg);
          transform: translateY(11px) translateX(0) rotate(45deg);
  background: #7ac414;
}
.button_container.active .middle {
  opacity: 0;
  background: #7ac414;
  width: 100%;
}
.button_container.active .bottom {
  -webkit-transform: translateY(-11px) translateX(0) rotate(-45deg);
          transform: translateY(-11px) translateX(0) rotate(-45deg);
  background: #7ac414;
  width: 100%;
}
.button_container span {
  background: #7ac414;
  height: 2px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  cursor: pointer;
}
.button_container span:nth-of-type(2) {
  top: 11px;
}
.button_container span:nth-of-type(3) {
  top: 22px;
}
@media screen and (min-width: 1024px) {
  .button_container {
    display: none;
  }
}
/*
    .overlay{
        width: 0%;
        height: 100%;
        right:0; instead of left:0
        &.open{
            width: 100%
        }
    }
*/
.overlay {
  position: fixed;
  background: #1C1C1C;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: auto;
  z-index: 400;
  content-visibility: auto;
}
.overlay .site-logo {
  margin-top: 30px;
  padding-inline: 30px;
}
@media screen and (min-width: 768px) {
  .overlay .site-logo {
    padding-inline: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .overlay .site-logo {
    padding-inline: 80px;
  }
}
@media screen and (min-width: 1600px) {
  .overlay .site-logo {
    padding-inline: 100px;
  }
}
.overlay.open {
  opacity: 1;
  visibility: visible;
  height: 100%;
}
.overlay.open li {
  -webkit-animation: fadeInRight 0.5s ease forwards;
          animation: fadeInRight 0.5s ease forwards;
  -webkit-animation-delay: 0.35s;
          animation-delay: 0.35s;
}
.overlay.open li:nth-of-type(2) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.overlay.open li:nth-of-type(3) {
  -webkit-animation-delay: 0.45s;
          animation-delay: 0.45s;
}
.overlay.open li:nth-of-type(4) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.overlay.open li:nth-of-type(5) {
  -webkit-animation-delay: 0.55s;
          animation-delay: 0.55s;
}
.overlay.open li:nth-of-type(6) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.overlay.open li:nth-of-type(7) {
  -webkit-animation-delay: 0.65s;
          animation-delay: 0.65s;
}
.overlay.open li:nth-of-type(8) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.overlay.open li:nth-of-type(9) {
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}
.overlay .overlay-nav-main {
  padding-inline: 30px;
}
@media screen and (min-width: 768px) {
  .overlay .overlay-nav-main {
    padding-inline: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .overlay .overlay-nav-main {
    padding-inline: 80px;
  }
}
@media screen and (min-width: 1600px) {
  .overlay .overlay-nav-main {
    padding-inline: 100px;
  }
}
.overlay .overlay-nav-main .overlay-menu {
  position: relative;
  font-size: 50px;
  font-weight: 400;
  text-align: left;
  margin-top: 35px;
}
.overlay .overlay-nav-main ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  height: 100%;
}
.overlay .overlay-nav-main ul li {
  display: block;
  position: relative;
  opacity: 0;
  margin: 0;
}
.overlay .overlay-nav-main ul li ul {
  display: none;
}
.overlay .overlay-nav-main ul li a {
  display: block;
  position: relative;
  color: #FFFFFF;
  text-decoration: none;
  overflow: hidden;
  font-size: 25px;
  text-transform: none;
  margin-top: 0px;
  font-weight: 500;
  margin-bottom: 0;
  font-weight: 700;
}
.overlay .overlay-nav-main ul li a:hover {
  opacity: 1;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
.form-placeholder {
  color: #061986 !important;
  opacity: 1;
  font-weight: 300;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
}
form textarea {
  height: 202px;
}
form input[type="submit"] {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-top: 0;
  width: 133px;
  margin: 0 auto;
  color: #061986;
}
@media screen and (min-width: 1024px) {
  form input[type="submit"] {
    float: left;
  }
}
form input[type="submit"]:hover {
  cursor: pointer;
}
form p {
  margin-bottom: 42px;
}
form input,
form textarea {
  padding: 8px 0;
  border: none;
  border-bottom: 1.5px solid #061986;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
}
form input:focus,
form textarea:focus {
  outline: none;
}
form ::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #061986 !important;
  opacity: 1;
  font-weight: 300;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
}
form :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #061986 !important;
  opacity: 1;
  font-weight: 300;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
}
form ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #061986 !important;
  opacity: 1;
  font-weight: 300;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
}
form :-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #061986 !important;
  opacity: 1;
  font-weight: 300;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
}
.right {
  text-align: right;
}
.left {
  text-align: left;
}
.center {
  text-align: center;
}
* {
  margin: 0;
  padding: 0;
}
.transform {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
button:focus,
input:focus {
  outline: none;
}
div {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
a:focus,
button:focus {
  outline: none;
}
.custom-btn {
  background-color: #7ac414;
  border: 2px solid #7ac414;
  padding: 8px 0px;
  font-weight: 300;
  text-decoration: none;
  display: block;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  border-radius: 50px;
  text-align: center;
}
.custom-btn:hover {
  background-color: transparent;
  color: #7ac414;
  opacity: 1;
}
img {
  height: auto;
}
.img-responsive {
  display: block;
  width: 100%;
}
/**********************************
* HEADER
***********************************/
header {
  padding: 30px 0;
  background-color: #1C1C1C;
  width: 100%;
  padding-inline: 30px;
  position: relative;
  z-index: 10;
  top: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
@media screen and (min-width: 768px) {
  header {
    padding-inline: 50px;
  }
}
@media screen and (min-width: 1024px) {
  header {
    padding-inline: 80px;
  }
}
@media screen and (min-width: 1600px) {
  header {
    padding-inline: 100px;
  }
}
header.fixed-header {
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  -webkit-box-shadow: 0 1px 6px 0 #202101;
          box-shadow: 0 1px 6px 0 #202101;
}
.site-logo {
  display: inline-block;
  width: 180px;
  margin-bottom: 0;
  position: relative;
  z-index: 65;
}
@media screen and (min-width: 768px) {
  .site-logo {
    width: 300px;
  }
}
@media screen and (min-width: 1024px) {
  .site-logo {
    width: auto;
  }
}
.site-logo svg {
  width: 180px;
  display: block;
}
@media screen and (min-width: 1024px) {
  .site-logo svg {
    width: 300px;
  }
}
.site-logo svg:hover path,
.site-logo svg:hover circle {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.site-logo svg:hover path,
.site-logo svg:hover circle {
  fill: #7ac414;
}
.site-logo img {
  width: 180px;
}
@media screen and (min-width: 1024px) {
  .site-logo img {
    width: 300px;
  }
}
.nav-main {
  float: right;
  display: none;
}
@media screen and (min-width: 1024px) {
  .nav-main {
    display: inline-block;
  }
}
.nav-main ul li {
  display: inline-block;
  margin-left: 28px;
}
@media screen and (min-width: 1500px) {
  .nav-main ul li {
    margin-left: 64px;
  }
}
.nav-main ul li ul {
  display: none;
}
.nav-main ul li a {
  color: #FFFFFF;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.nav-main ul li a:hover {
  color: #7ac414;
}
/**********************************
* HERO
***********************************/
.hero-section {
  position: relative;
}
.hero-section img {
  height: 100vh;
  max-height: 1080px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.hero-section .inner {
  position: absolute;
  left: 50%;
  bottom: 100px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 10;
}
.hero-section .inner h1 {
  text-align: center;
  color: #FFFFFF;
  text-transform: capitalize;
}
.hero-section::after {
  content: '';
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.65)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
  /* IE6-9 */
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
}
/**********************************
* HOME PAGE
***********************************/
.about-section {
  padding-inline: 30px;
  padding-block: 32px;
}
@media screen and (min-width: 768px) {
  .about-section {
    padding-inline: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .about-section {
    padding-inline: 80px;
  }
}
@media screen and (min-width: 1600px) {
  .about-section {
    padding-inline: 100px;
  }
}
@media screen and (min-width: 768px) {
  .about-section {
    padding-block: 64px;
  }
}
.about-section .container {
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: minmax(0, 1fr);
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 50px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media screen and (min-width: 1024px) {
  .about-section .container {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  .about-section .container img {
    aspect-ratio: 1;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.about-section .container h2 {
  color: #061986;
}
.service-section {
  padding-inline: 30px;
  padding-block: 32px;
}
@media screen and (min-width: 768px) {
  .service-section {
    padding-inline: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .service-section {
    padding-inline: 80px;
  }
}
@media screen and (min-width: 1600px) {
  .service-section {
    padding-inline: 100px;
  }
}
@media screen and (min-width: 768px) {
  .service-section {
    padding-block: 64px;
  }
}
.service-section .container {
  max-width: 1400px;
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: minmax(0, 1fr);
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 50px;
}
@media screen and (min-width: 1024px) {
  .service-section .container {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.service-section .container .service {
  text-align: center;
}
.service-section .container .service img {
  max-width: 150px;
  aspect-ratio: 1;
  -o-object-fit: cover;
     object-fit: cover;
  margin-inline: auto;
  margin-bottom: 40px;
}
.service-section .container .service h3 {
  color: #c70909;
}
.fullImage img {
  max-height: 800px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.gallery-section {
  padding-block: 32px;
}
@media screen and (min-width: 768px) {
  .gallery-section {
    padding-block: 64px;
  }
}
.gallery-section .container .galleryintro {
  padding-inline: 30px;
  max-width: 900px;
  width: 100%;
  margin-inline: auto;
  text-align: center;
  margin-bottom: 60px;
}
@media screen and (min-width: 768px) {
  .gallery-section .container .galleryintro {
    padding-inline: 50px;
  }
}
@media screen and (min-width: 1024px) {
  .gallery-section .container .galleryintro {
    padding-inline: 80px;
  }
}
@media screen and (min-width: 1600px) {
  .gallery-section .container .galleryintro {
    padding-inline: 100px;
  }
}
.gallery-section .container .galleryintro h2 {
  color: #7ac414;
}
.gallery-section .container .galley-slider-div {
  margin-bottom: 50px;
  max-width: 1200px;
  width: 100%;
  margin-inline: auto;
}
.gallery-section .container .galley-slider-div .slick-track {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.gallery-section .container .galley-slider-div .slick-slide {
  height: inherit !important;
}
.gallery-section .container .galley-slider-div img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 600px;
  width: 100%;
  display: block;
}
@media screen and (min-width: 1024px) {
  .gallery-section .container .galley-slider-div img {
    height: 800px;
  }
}
.gallery-section .container .galley-slider-div .gallery {
  position: relative;
}
.gallery-section .container .galley-slider-div .gallery .caption {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px 40px;
  background-color: #c70909;
  color: #FFFFFF;
}
/**********************************
* Hotels page
***********************************/
.all-hotels .hotel-link h2 {
  margin-top: 20px;
}
.all-hotels .hotel-link h2,
.all-hotels .hotel-link img {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.all-hotels .hotel-link:hover {
  text-decoration: none !important;
}
.all-hotels .hotel-link:hover h2 {
  color: #c70909;
}
.all-hotels .hotel-link:hover img {
  -webkit-filter: grayscale(0.5);
          filter: grayscale(0.5);
}
.all-hotels .hotel-feature-image {
  aspect-ratio: 1;
  -o-object-fit: cover;
     object-fit: cover;
}
/**********************************
* FOOTER
***********************************/
footer {
  padding-block: 32px;
  background-color: #1C1C1C;
}
@media screen and (min-width: 768px) {
  footer {
    padding-block: 64px;
  }
}
footer a {
  text-decoration: underline;
}
footer a:hover {
  color: #fdf900 !important;
}
footer .details {
  margin-top: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media screen and (min-width: 768px) {
  footer .details {
    gap: 80px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
footer .details a,
footer .details p,
footer .details span {
  color: #FFFFFF;
}
footer .details p {
  margin-bottom: 0px;
}
footer .details svg path {
  fill: #FFFFFF;
}
footer .details .contact a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
footer .details .contact a svg {
  height: 30px;
  width: 30px;
}
footer .social-media {
  margin-top: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 50px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
footer .social-media a {
  color: #FFFFFF;
}
footer .copyright {
  margin-top: 52.5px;
  padding-bottom: 52.5px;
}
footer .copyright span,
footer .copyright a {
  font-size: 12px;
  margin: 0;
  color: #FFFFFF;
}
@media screen and (min-width: 600px) {
  footer .copyright span,
  footer .copyright a {
    width: auto;
  }
}
footer .copyright .copyright-text {
  display: block;
}
@media screen and (min-width: 1024px) {
  footer .copyright .copyright-text {
    display: inline-block;
  }
}
footer .copyright span:nth-of-type(2) {
  display: inline-block;
  margin-bottom: 0;
}
@media screen and (min-width: 1024px) {
  footer .copyright span:nth-of-type(2) {
    display: inline-block;
  }
}
